// var md = new MobileDetect(window.navigator.userAgent);

var $ = jQuery.noConflict();


function initHomepage() {

	if ($('.hero-swiper').length > 0) {
		var swiper = new Swiper('.hero-swiper', {
			pagination: {
				el: '.hero-swiper .swiper-pagination',
			},
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			loop: true,
			autoplay: {
				delay: 6000,
			},
		});
	}
}

function initGlobal() {
	$('.menu-bt,.close-menu').click(function () {
		$('.menu-wrapper').toggleClass('open');
	});

	$('.menu a').click(function () {
		$('.menu-bt').click();
	});

	$(window).scroll(function () {
		adjustHeader();
	});
	adjustHeader();
}

function adjustHeader() {
	var scroll = $(window).scrollTop();
	if (scroll > 0) {
		$('header').addClass('scrolled');
	} else {
		$('header').removeClass('scrolled');
	}

}

function initDoctor() {

	if ($('.doctor-review-swiper').length > 0) {
		var swiper = new Swiper('.doctor-review-swiper', {
			pagination: {
				el: '.doctor-review-swiper .swiper-pagination',
			},
			loop: true,
			autoplay: {
				delay: 6000,
			},
		});
	}
}

$(document).ready(function () {
	initGlobal();
	initHomepage();
	initDoctor();
});
